export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Catalog",
    path: '/catalog',
  },
  {
    title: "Project",
    path: "/project",
  },
  {
    title: "About Us",
    path: "/about",
  },
  {
    title: "Contact Us",
    path: "/contact",
  },
  {
    title:"Rate Us",
    path:"/rateus",
  }
];
